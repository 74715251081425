import AWS from 'aws-sdk';

// Настройка AWS SDK
AWS.config.update({
    region: 'us-east-1', // Например, 'us-west-2'
    accessKeyId: 'AKIAWFZPTO3NNGVJI2WS', // Получите из AWS IAM
    secretAccessKey: 'aSn5OlOOFaFPfP8W1d0Uyw52w4IvsCQBoT8yTzAL' // Получите из AWS IAM
});

const dynamoDb = new AWS.DynamoDB.DocumentClient();
export default dynamoDb;
