import React, {useState, useEffect} from 'react';
import {Select, MenuItem, TextField, Button, Typography, Grid, Box, IconButton} from '@mui/material';

import {AccountCircle} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {buyTokenProcess, fetchUsers} from "../db/dbService";

const Market = () => {
    const [users, setUsers] = useState([]);
    const [userTokens, setUserTokens] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [sellToken, setSellToken] = useState('');
    const [buyToken, setBuyToken] = useState('');
    const [amount, setAmount] = useState('');
    const [estimatedReceive, setEstimatedReceive] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [maxAmount, setMaxAmount] = useState(0);
    const [editingField, setEditingField] = useState(null);
    const [searchTermBuyer, setSearchTermBuyer] = useState('');
    const [searchTermToken, setSearchTermToken] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await fetchUsers();
                setUsers(userData);

            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to load data");
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedUser) {
            const user = users.find(u => u.id.toString() === selectedUser.toString());
            const userWallet = user?.wallet || [];
            if (user) {
                const userTokensWithDetails = userWallet
                    .filter((t) => t.amount > 0)
                    .map(userToken => {
                    const tokenDetails = users.find(u => u.id.toString() === userToken.userId.toString());
                    return {
                        ...userToken,
                        name: tokenDetails?.token.title || 'Unknown',
                        price: tokenDetails?.token.price || 0
                    };
                });

                // Sorting by token name
                userTokensWithDetails.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });

                setUserTokens(userTokensWithDetails);
            } else {
                setUserTokens([]);
            }
        } else {
            setUserTokens([]);
        }
    }, [selectedUser, users]);

    useEffect(() => {
        if (selectedUser && sellToken) {
            const sellTokenInfo = userTokens.find(t => t.userId.toString() === sellToken.toString());
            setMaxAmount(sellTokenInfo.amount)
        } else {
            setMaxAmount(0);
        }
    }, [selectedUser, sellToken, amount, users, userTokens]);

    useEffect(() => {
        if (editingField === 'estimatedReceive' && sellToken && buyToken && estimatedReceive) {
            const sellTokenInfo = users.find(u => u.id.toString() === sellToken.toString()).token;
            const buyTokenInfo = users.find(u => u.id.toString() === buyToken.toString()).token;
            if (sellTokenInfo && buyTokenInfo) {
                const qcCoinAmount = parseFloat(estimatedReceive) / buyTokenInfo.price;
                const sellAmount = (qcCoinAmount * sellTokenInfo.price).toFixed(7);
                setAmount(parseFloat(sellAmount));
            }
        }
        if (editingField === 'amount' && sellToken && buyToken && amount) {
            const sellTokenInfo = users.find(u => u.id.toString() === sellToken.toString()).token;
            const buyTokenInfo = users.find(u => u.id.toString() === buyToken.toString()).token;

            if (sellTokenInfo && buyTokenInfo) {
                const qcCoinAmount = parseFloat(amount) / sellTokenInfo.price;
                const estimatedAmount = qcCoinAmount * buyTokenInfo.price;

                 setEstimatedReceive(parseFloat(estimatedAmount).toFixed(7));
            }
        }
    }, [editingField, sellToken, buyToken, estimatedReceive, users, amount]);

    const handleExchange = async () => {
        if (!selectedUser || !sellToken || !buyToken || !amount) {
            setError("Заповни всі поля");
            return;
        }

        try {
            await buyTokenProcess(selectedUser, sellToken, buyToken, amount)
            setError('');
            setAmount('');
            setSuccess('Токен куплено!');
            setTimeout(() => setSuccess(''), 3000);
        } catch (err) {
            console.error("Error during exchange:", err);
            setError("Помилка покупки токена!");
        }
    };
    const handleResetBuyer = () => {
        setSearchTermBuyer('');
    };
    const handleResetTokens = () => {
        setSearchTermToken('');
    };
    const filteredBuyerUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTermBuyer.toLowerCase())
    );
    const filteredTokens = users.filter(user =>
        user.token.title.toLowerCase().includes(searchTermToken.toLowerCase())
    );

    const getTokenTitle = (userId) =>{
        const user = users.find(u => u.id.toString() === userId.toString());
        return user ? user.token.title : 'Unknown User'
    }
    return (
        <div>
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        <TextField id="input-with-sx" label="Пошук" variant="standard"
                                   value={searchTermBuyer}
                                   onChange={(e) => setSearchTermBuyer(e.target.value)}/>
                        {searchTermBuyer && <IconButton onClick={handleResetBuyer} color="inherit" size="small">
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                    </Box>
                </Grid>
            </Grid>
            <Select
                value={selectedUser}
                onChange={(e) => {setSellToken('');setSelectedUser(e.target.value)}}
                displayEmpty
                fullWidth
                style={{margin: '1% 0', padding: '5px'}}
            >
                <MenuItem value="" disabled>Покупець</MenuItem>
                {filteredBuyerUsers.map(user => (
                    <MenuItem key={user.id.toString()} value={user.id}>{user.name}</MenuItem>
                ))}
            </Select>
            <Select
                value={sellToken}
                onChange={(e) => {setSellToken(e.target.value)}}
                displayEmpty
                fullWidth
                disabled={!selectedUser}
                style={{margin: '1% 0', padding: '5px'}}
            >
                <MenuItem value="" disabled>Токен який продає</MenuItem>
                {userTokens.map(token => (
                    <MenuItem key={token.userId.toString()} value={token.userId.toString()}>
                        {token.name} ({parseFloat(token.amount.toFixed(7))}) - (1 QCC = {token.price} {token.name} )
                    </MenuItem>
                ))}
            </Select>

            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <TextField
                            type="number"
                            label="Скільки продати токенів"
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value);
                                setEditingField('amount');
                            }}
                            fullWidth
                            disabled={!selectedUser || !sellToken || !buyToken}
                        />
                        <Button onClick={() => {setAmount(maxAmount); setEditingField('amount');}}
                                variant="contained"
                                color="primary"
                                sx={{
                                    height: '50px',
                                    fontSize: '1.5rem',
                                    padding: '10px 20px',
                                }}
                                disabled={!selectedUser || !sellToken || !buyToken}
                                style={{margin: '0rem 1rem'}}>
                            MAX
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>

                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        <TextField id="input-with-sx" label="Пошук" variant="standard"
                                   value={searchTermToken}
                                   onChange={(e) => setSearchTermToken(e.target.value)}/>
                        {searchTermToken && <IconButton onClick={handleResetTokens} color="inherit" size="small">
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                    </Box>
                </Grid>
            </Grid>

            <Select
                value={buyToken}
                onChange={(e) => setBuyToken(e.target.value)}
                displayEmpty
                fullWidth
                style={{margin: '1% 0', padding: '5px'}}
                disabled={!selectedUser || !sellToken}
            >
                <MenuItem value="" disabled>Токен який покупає</MenuItem>
                {filteredTokens.filter(u => u.id.toString() !== sellToken.toString()).map(user => (
                    <MenuItem key={user.id.toString()} value={user.id.toString()}>
                        {getTokenTitle(user.id)} - (1 QCC = {user.token.price} {getTokenTitle(user.id)} )
                    </MenuItem>
                ))}
            </Select>
            <TextField
                type="number"
                label="Скільки купити токена"
                value={estimatedReceive}
                onChange={(e) => {
                    setEstimatedReceive(e.target.value);
                    setEditingField('estimatedReceive');
                }}
                fullWidth
                disabled={!selectedUser || !buyToken}
            />
            {(selectedUser && sellToken) &&
                <Typography variant="h6" style={{marginTop: '1rem'}}>
                    {selectedUser && sellToken && users.find(u => u.id.toString() === selectedUser.toString())?.name} продає {amount} {sellToken && users.find(t => t.id.toString() === sellToken)?.token.title} щоб
                    купити {estimatedReceive} {buyToken && users.find(t => t.id.toString() === buyToken)?.token.title}
                </Typography>}
            {error && <Typography color="error">{error}</Typography>}
            <Typography variant="body1" color="success.main" style={{marginBottom: '1rem'}}>
                {success}
            </Typography>
            <Button
                onClick={handleExchange}
                variant="contained"
                color="primary"
                sx={{
                    width: '100%',
                    height: '50px',
                    fontSize: '1.5rem',
                    padding: '10px 20px',
                }}
                style={{margin: '1rem 0'}}
                disabled={!selectedUser || !sellToken || !buyToken || !amount}
            >
                Купити
            </Button>
        </div>
    );
};

export default Market;