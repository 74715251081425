// dbService.js
import dynamoDb from '../db/awsConfig';
export const getSettings = async () => {
    try {
        const params = {
            TableName: 'Settings',
        };
        const result = await dynamoDb.scan(params).promise();
        return result.Items;
    } catch (err) {
        console.error(err);
        throw new Error('Failed to load Settings');
    }
};
export const updateSettings = async (settings) => {
    try {
        const paramsPut = {
            TableName: 'Settings',
            Item: settings,
        };
        await dynamoDb.put(paramsPut).promise();
    } catch (err) {
        console.error(err);
        throw new Error('Failed updateSettings');
    }
};
// Function to fetch users from the database
export const fetchUsers = async () => {
    try {
        const params = {
            TableName: 'Users',
        };
        const result = await dynamoDb.scan(params).promise();
        return result.Items;
    } catch (err) {
        console.error(err);
        throw new Error('Failed to load users');
    }
};
export const updateUser = async (user) => {
    try {
        const paramsPut = {
            TableName: 'Users',
            Item: user,
        };
        await dynamoDb.put(paramsPut).promise();
    } catch (err) {
        console.error(err);
        throw new Error('Failed updateSettings');
    }
};
const getUserById = async (userId) => {
    const paramsGet = {
        TableName: 'Users',
        Key: { id: userId },
    };
    const result = await dynamoDb.get(paramsGet).promise();
    return result.Item;
};

export const transferToken = async (fromUser, toUser, token, amount) => {
    try {
        // Получаем данные пользователей
        const fromUserData = await getUserById(fromUser);
        const toUserData = await getUserById(toUser);

        if (!fromUserData || !toUserData) {
            throw new Error('User not found');
        }

        // Находим токен в кошельке отправителя
        const fromUserToken = fromUserData.wallet.find(t => t.userId === token);
        if (!fromUserToken || fromUserToken.amount < amount) {
            throw new Error('Insufficient token balance');
        }

        // Обновляем баланс токена у отправителя
        fromUserToken.amount -= amount;

        // Находим или создаем токен в кошельке получателя
        let toUserToken = toUserData.wallet.find(t => t.userId === token);
        if (!toUserToken) {
            toUserToken = { userId: token, amount: 0 };
            toUserData.wallet.push(toUserToken);
        }

        // Обновляем баланс токена у получателя
        toUserToken.amount += Number(amount);

        // Обновляем данные пользователей в базе данных
        await updateUser(fromUserData);
        await updateUser(toUserData);

        console.log('Token transfer successful');
    } catch (err) {
        console.error(err);
        throw new Error('Failed to transfer token');
    }
};
// Функция для покупки токена
export const buyTokenProcess = async (selectedUser, sellToken, buyToken, amount) => {
    try {
        // Получаем данные пользователя
        const userData = await getUserById(selectedUser);
        const sellTokenData = await getUserById(sellToken);
        const buyTokenData = await getUserById(buyToken);
        if (!userData) {
            throw new Error('User not found');
        }

        // Находим токен, который продается, в кошельке пользователя
        const userSellToken = userData.wallet.find(t => t.userId === sellToken);
        if (!userSellToken || userSellToken.amount < amount) {
            throw new Error('Insufficient sell token balance');
        }

        // Находим или создаем токен, который покупается, в кошельке пользователя
        let userBuyToken = userData.wallet.find(t => t.userId === buyToken);
        if (!userBuyToken) {
            userBuyToken = { userId: buyToken, amount: 0 };
            userData.wallet.push(userBuyToken);
        }

        const qcCoinAmount = amount / sellTokenData.token.price;
        const estimatedAmount = qcCoinAmount * buyTokenData.token.price;

        // Обновляем балансы токенов
        userSellToken.amount = Number(Number(userSellToken.amount) - Number(amount));
        userBuyToken.amount = Number(Number(userBuyToken.amount) + Number(estimatedAmount));

        // Обновляем данные пользователя в базе данных
        await updateUser(userData);

        console.log('Token purchase successful');
    } catch (err) {
        console.error(err);
        throw new Error('Failed to buy token');
    }
};
// Function to add a user to the database
export const addUser = async (user) => {
    try {
        const paramsPut = {
            TableName: 'Users',
            Item: user,
        };
        await dynamoDb.put(paramsPut).promise();
    } catch (err) {
        console.error(err);
        throw new Error('Failed to add user');
    }
};
export const addKid = async (kid) => {
    try {
        const userData = await getUserById(kid.userId);
        if (!userData) {
            throw new Error('User not found');
        }
        if (!userData.kids) {
            userData.kids = [];
        }
        userData.kids.push({ nick: kid.nick, role: kid.role });

        await updateUser(userData);
    } catch (err) {
        console.error(err);
        throw new Error('Failed to add user');
    }
};

// Function to delete a user from the database
export const deleteUser = async (userId) => {
    try {
        const paramsDelete = {
            TableName: 'Users',
            Key: { id: userId },
        };
        await dynamoDb.delete(paramsDelete).promise();
    } catch (err) {
        console.error(err);
        throw new Error('Failed to delete user');
    }
};
export const deleteKid = async (kidNick, userId) => {
    try {
        // Retrieve user data
        const userData = await getUserById(userId);
        if (!userData) {
            throw new Error('User not found');
        }

        // Ensure the user has kids
        if (!userData.kids || userData.kids.length === 0) {
            throw new Error('User has no kids to delete');
        }

        // Filter out the kid to be deleted
        const updatedKids = userData.kids.filter(kid => kid.nick !== kidNick);

        // Check if the kid was found and removed
        if (updatedKids.length === userData.kids.length) {
            throw new Error(`Kid with nick '${kidNick}' not found`);
        }

        // Update the user's kids array
        userData.kids = updatedKids;
        await updateUser(userData);
    } catch (err) {
        console.error(err);
        throw new Error('Failed to delete kid');
    }
};
