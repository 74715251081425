import React, {useState, useEffect, useCallback} from 'react';
import {Button, Select, MenuItem, TextField, Typography, Grid, Box, IconButton} from '@mui/material';
import {AccountCircle} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {fetchUsers, transferToken} from '../db/dbService'; // Import the database service functions

const TransferTokensForm = () => {
    const [users, setUsers] = useState([]);
    const [fromUser, setFromUser] = useState('');
    const [token, setToken] = useState('');
    const [amount, setAmount] = useState('');
    const [toUser, setToUser] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTokenBalance, setSelectedTokenBalance] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [maxAmount, setMaxAmount] = useState(0);
    const [searchTermFrom, setSearchTermFrom] = useState('');
    const [searchTermTo, setSearchTermTo] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await fetchUsers();
                setUsers(userData);
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, [isLoading]);


    useEffect(() => {
        if (fromUser && token) {
            const selectedUser = users.find(user => user.id === fromUser);
            const selectedToken = selectedUser?.wallet.find(t => t.userId === token);
            setSelectedTokenBalance(selectedToken ? selectedToken.amount : 0);
        }
    }, [fromUser, token, users]);

    const getUserTokens = useCallback((userId) => {
        if (!userId) return [];
        const user = users.find(u => u.id.toString() === userId.toString());
        if (!user) return [];
        const result = user.wallet.filter((t) => t.amount > 0);
        //console.log(result);
        return result;
    }, [users]);

    const handleFromUserChange = (e) => {
        const newFromUser = e.target.value;
        setFromUser(newFromUser);
        setToken('');
    };

    const handleAmountChange = (e) => {
        const inputAmount = e.target.value;
        setAmount(inputAmount);
        if (parseInt(inputAmount, 10) > selectedTokenBalance) {
            setError('Недостатньо коштів');
        } else {
            setError('');
        }
    };

    const handleTransfer = async () => {
        if (!fromUser || !token || !amount || !toUser) {
            setError("Заповніть всі поля");
            return;
        }
        if (parseInt(amount, 10) > selectedTokenBalance) {
            setError('Недостатньо коштів');
            return;
        }
        setIsLoading(true);

        try {

            await transferToken(fromUser, toUser, token, amount);
            setError('');
            setAmount('');
            setSuccess('Токен передано!'); // Add this line
            setTimeout(() => setSuccess(''), 3000); // Add this line
        } catch (err) {
            console.error("Failed to transfer token:", err);
            setError('Помилка при передачі токена');
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (fromUser && token) {
            const transTokenInfo = getUserTokens(fromUser).find(t => t.userId.toString() === token.toString())
            setMaxAmount(transTokenInfo.amount)
        } else {
            setMaxAmount(0);
        }
    }, [fromUser, token, getUserTokens]);

    const handleResetFrom = () => {
        setSearchTermFrom('');
    };
    const handleResetTo = () => {
        setSearchTermTo('');
    };
    const filteredFromUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTermFrom.toLowerCase())
    );
    const filteredToUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTermTo.toLowerCase())
    );

    return (
        <div>
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        <TextField id="input-with-sx" label="Пошук" variant="standard"
                                   value={searchTermFrom}
                                   onChange={(e) => setSearchTermFrom(e.target.value)}/>
                        {searchTermFrom && <IconButton onClick={handleResetFrom} color="inherit" size="small">
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                    </Box>
                </Grid>
            </Grid>
            <Select
                value={fromUser}
                onChange={handleFromUserChange}
                displayEmpty
                fullWidth
                style={{margin: '1% 0', padding: '5px'}}
            >
                <MenuItem value="" disabled>Хто передає</MenuItem>
                {filteredFromUsers.map(user => (
                    <MenuItem key={user.id.toString()} value={user.id.toString()}>{user.name}</MenuItem>
                ))}
            </Select>
            <Select
                value={token}
                onChange={(e) => setToken(e.target.value)}
                displayEmpty
                disabled={!fromUser}
                fullWidth
                style={{margin: '1% 0', padding: '5px'}}
            >
                <MenuItem value="" disabled>Виберіть токен</MenuItem>
                {getUserTokens(fromUser).map((t, index) => {
                    const user = users.find(u => u.id.toString() === t.userId.toString());
                    return (
                        <MenuItem key={index} value={t.userId.toString()}>
                            {user ? user.token.title : 'Unknown User'} ({parseFloat(t.amount.toFixed(7))})
                        </MenuItem>
                    );
                })}
            </Select>
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <TextField
                            type="number"
                            label="Кількість"
                            value={amount}
                            onChange={handleAmountChange}
                            error={!!error}
                            helperText={error}
                            fullWidth
                            style={{margin: '1% 0', padding: '5px'}}
                        />
                        <Button onClick={() => setAmount(maxAmount)} variant="contained"
                                color="primary"
                                sx={{
                                    height: '50px',
                                    fontSize: '1.5rem',
                                    padding: '10px 20px',
                                }}
                                style={{margin: '0rem 1rem'}}>
                            MAX
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>

                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        <TextField id="input-with-sx" label="Пошук" variant="standard"
                                   value={searchTermTo}
                                   onChange={(e) => setSearchTermTo(e.target.value)}/>
                        {searchTermTo && <IconButton onClick={handleResetTo} color="inherit" size="small">
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                    </Box>
                </Grid>
            </Grid>
            <Select
                value={toUser}
                onChange={(e) => setToUser(e.target.value)}
                displayEmpty
                fullWidth
                style={{margin: '1% 0', padding: '5px'}}
            >
                <MenuItem value="" disabled>Кому передати</MenuItem>
                {filteredToUsers.filter(user => user.id.toString() !== fromUser).map(user => (
                    <MenuItem key={user.id.toString()} value={user.id.toString()}>{user.name}</MenuItem>
                ))}
            </Select>
            <Typography variant="body1" color="success.main" style={{marginBottom: '1rem'}}>
                {success}
            </Typography>
            <Button
                sx={{
                    width: '100%',
                    height: '50px',
                    fontSize: '1.5rem',
                    padding: '10px 20px',
                }}
                style={{margin: '1rem 0'}}
                onClick={handleTransfer}
                variant="contained"
                color="primary"
                disabled={!!error || isLoading}>
                Передати
            </Button>
        </div>
    );
};

export default TransferTokensForm;