import React, {useState, useEffect} from 'react';
import {
    Badge,
    Box,
    Chip,
    Container,
    Grid,
    IconButton,
    TextField
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from "@mui/material/Skeleton";
import {AccountCircle} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {fetchUsers, getSettings, updateSettings, updateUser} from '../db/dbService'; // Import the database service functions


const TokenList = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const [priceIncrease, setPriceIncrease] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        setIsLoading(true)
        const fetchSettings = async () => {
            setIsLoading(true)
             try {
                const userData = await fetchUsers();
                const settingsData = await getSettings();

                setUsers(userData);
                setPriceIncrease(settingsData.find(s => s.name === 'priceIncrease')?.value || 1)

            } catch (err) {
                console.error("Error fetching settings:", err);
            } finally {
                setIsLoading(false)
            }
        };

        fetchSettings();
    }, []);

    const handleChangeTokenPrice = async (user, increment) => {
        setIsChange(true)
        try {
            const updatedUser = {
                ...user,
                token: { title: user.token.title, price: Number(user.token.price) + Number(increment) }
            };
            await updateUser(updatedUser);

            // Обновление данных после изменения цены токена
            const updatedUsers = users.map((u, index) =>
                u.id === user.id ? updatedUser : u
            );
            setUsers(updatedUsers);
        } catch (err) {
            console.error(err);
        } finally {
            setIsChange(false)
        }
    };

    const filteredTokens = users.filter(user =>
        user.token.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleReset = () => {
        setSearchTerm('');
    };
    const calculateTokenInWallets = (tokenUserId) => {
        let total = 0;
        users.forEach(user => {
            const tokenInWallet = user.wallet.find(t => t.userId.toString() === tokenUserId.toString());
            if (tokenInWallet) {
                total += Number(tokenInWallet.amount);
            }
        });
        return Math.round(total).toString();
    };
    return (
        <Container>
            {isLoading &&
                <Skeleton animation="wave" style={{margin: '2% 0', padding: '15px'}} variant="rounded" width={'100%'}
                          height={80}/>}
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        <TextField id="input-with-sx" label="Пошук" variant="standard"
                                   value={searchTerm}
                                   onChange={(e) => setSearchTerm(e.target.value)}/>
                        {searchTerm && <IconButton onClick={handleReset} color="inherit" size="small">
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>

                </Grid>
            </Grid>

            {filteredTokens.map((user, index) => {
                const tokenInWallets = calculateTokenInWallets(user.id);
                const token = user.token;
                return (
                    <Grid container key={index} spacing={1} justifyContent="center" style={{margin: '1% 0', padding: '5px', border: '1px solid gray'}}>
                        <Grid item xs={12} >
                            <Box display="flex" alignItems="center">
                                <Badge badgeContent={tokenInWallets} color="warning" max={999}>
                                    {token.title}⠀
                                </Badge>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                style={{margin: '5px'}}
                                loading={isChange}
                                loadingPosition="start"
                                size="large"
                                startIcon={<RemoveCircleIcon/>}
                                variant="contained"
                                onClick={() => handleChangeTokenPrice(user, priceIncrease)}
                                disabled={token.title === 'QCCoin'}
                            ></LoadingButton>
                            <Chip label={`${parseFloat((1 / token.price).toFixed(5))} (1/${token.price}) QCC`} color="primary"/>
                            <LoadingButton
                                style={{margin: '5px'}}
                                loading={isChange}
                                loadingPosition="start"
                                size="large"
                                startIcon={<AddCircleIcon fontSize="inherit"/>}
                                variant="contained"
                                disabled={token.title === 'QCCoin'}
                                onClick={() => handleChangeTokenPrice(user, -priceIncrease)}
                            ></LoadingButton>
                        </Grid>

                    </Grid>
                )
            })}

        </Container>
    );
};

export default TokenList;
