import React from 'react';
import {Chip, Container, Divider, Toolbar} from '@mui/material';
import {Link} from "react-router-dom";
import {Stack} from "@mui/system";

const Footer = () => {
    return (
        <footer>
                    <Container maxWidth="xl">
                        <Toolbar style={{justifyContent: "center"}}>
                            <Stack direction="row"
                                   divider={<Divider orientation="vertical" flexItem/>}
                                   spacing={2}
                            >
                                <Link color="inherit" target="_blank" to={'https://www.instagram.com/questycamp/'}>
                                    <Chip label={'© 2024 Questy Camp'} variant="outlined"/>
                                </Link>
                                <Link color="inherit" target="_blank" to={'/test'}>
                                    <Chip label={"v 2.05"} variant="outlined"/>
                                </Link>
                            </Stack>
                        </Toolbar>
                    </Container>
        </footer>
    );
};

export default Footer;
