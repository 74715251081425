import React, {useState, useEffect} from 'react';
import {
    Box,
    Container,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Skeleton from "@mui/material/Skeleton";
import {AccountCircle} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {fetchUsers, addKid, deleteKid, getSettings} from '../db/dbService'; // Import the database service functions

const KidsList = () => {
    const [users, setUsers] = useState([]);
    const [settings, setSettings] = useState([]);
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selecteMom, setSelectedMom] = useState('');
    const [kidsList, setKidsList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const result = await fetchUsers();
                setUsers(result);

                const settingsData = await getSettings();
                setSettings(settingsData);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [isChanged]);

    useEffect(() => {
        const list = [];
        users.forEach(u => {
            if (u.kids) {
                u.kids.forEach(kid => {
                    list.push({
                        nick: kid.nick,
                        role: kid.role,
                        momId: u.id,
                        momName: u.name
                    });
                })
            }
        })
        setKidsList(list)
        console.log(list)
    }, [users]);

    const handleAddUser = async () => {
        if (!name || !role || !selecteMom) {
            setError('Заповніть поля');
            return;
        }

        setIsChanged(true);

        try {

            const newKids = {
                nick: name,
                role: role,
                userId: selecteMom,

            };
            const user = users.find(u => u.id.toString() === selecteMom.toString())
            if (!user.kids) {
                user.kids = [];
            }
            user.kids.push(newKids)
            await addKid(newKids);

            setUsers((prevUsers) => [...prevUsers, user]);
            setName('');
            setRole('');
            setError('');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsChanged(false);
        }
    };

    const handleDeleteUser = async (nick, momId) => {
        const confirmDelete = window.confirm("Ви точно хочете видалити користувача?");
        if (!confirmDelete) {
            return;
        }

        setIsChanged(true);
        try {
            await deleteKid(nick, momId);
            setUsers((prevUsers) => prevUsers.filter(user => user.id !== momId));
            setError('');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsChanged(false);
        }
    };

    const filteredUsers = kidsList.filter(kid =>
        kid.nick.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleReset = () => {
        setSearchTerm('');
    };

    return (
        <Container>
            {isLoading && users.length === 0 &&
                <Skeleton animation="wave" style={{margin: '2% 0', padding: '15px'}} variant="rounded" width={'100%'}
                          height={80}/>}
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        <TextField
                            id="input-with-sx"
                            label="Пошук"
                            variant="standard"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm && <IconButton onClick={handleReset} color="inherit" size="small">
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
            </Grid>

            <List>
                {filteredUsers.map((kid,i) => (
                    <ListItem key={i}>
                        <ListItemText primary={`${kid.nick} (${kid.role})`} secondary={kid.momName}/>
                        <LoadingButton
                            style={{margin: '5px'}}
                            loading={isChanged}
                            loadingPosition="start"
                            size="large"
                            startIcon={<DeleteForeverIcon fontSize="inherit"/>}
                            variant="contained"
                            onClick={() => handleDeleteUser(kid.nick, kid.momId)}
                        >
                            Видалити
                        </LoadingButton>
                    </ListItem>
                ))}
            </List>

            <TextField
                label="Нік"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={!!isChanged}
            />
            <TextField
                label="Роль"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                disabled={!!isChanged}
            />
            <Select
                value={selecteMom}
                onChange={(e) => {
                    setSelectedMom(e.target.value)
                }}
                displayEmpty
                fullWidth
                style={{margin: '1% 0', padding: '5px'}}
            >
                <MenuItem value="" disabled>Мамочка</MenuItem>
                {users.map(user => (
                    <MenuItem key={user.id.toString()} value={user.id}>{user.name}</MenuItem>
                ))}
            </Select>
            <LoadingButton
                style={{margin: '5px'}}
                loading={isChanged}
                loadingPosition="start"
                size="large"
                startIcon={<AddCircleIcon fontSize="inherit"/>}
                variant="contained"
                onClick={handleAddUser}
            >
                Додати
            </LoadingButton>

            {error && <p>{error}</p>}
        </Container>
    );
};

export default KidsList;
