import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';

import UserBar from "../components/UserBar";
import Footer from "../components/Footer";
import Skeleton from "@mui/material/Skeleton";
import {AccountCircle} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import {fetchUsers} from "../db/dbService";
import { useMemo } from 'react';

const UserTokenList = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const result = await fetchUsers();
                setUsers(result);
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to load data");
            }finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const getUserWalletTokens = (userId) => {
        if (users.length <= 0 || !userId) return ;
        const user = users.find(u => u.id.toString() === userId.toString()) || [];
        if (user) {
            const userWallet = user.wallet;
            return userWallet.map(userToken => {
                const tokenDetails = users.find(u => u.id.toString() === userToken.userId.toString());
                const totalPrice = userToken.amount * (1/tokenDetails?.token.price); // calculate total price for each token
                return { ...userToken, name: tokenDetails?.token.title || 'Unknown', totalPrice };
            });
        }
        return [];
    };
    const getUserKids = (userId) => {
        if (users.length <= 0 || !userId) return ;
        const user = users.find(u => u.id.toString() === userId.toString()) || [];
        console.log(user.kids)
        if (user && user.kids) {
            return user.kids;
        }
        return [];
    };


    const filteredUsers = useMemo(() => {
        return users
            .filter(user => {
                if (user.name === 'QC') {
                    return false; // Исключаем пользователя с именем "QC"
                }
                const matchesName = user.name.toLowerCase().includes(searchTerm.toLowerCase());
                const matchesKidsNick = user.kids.some(kid =>
                    kid.nick.toLowerCase().includes(searchTerm.toLowerCase())
                );

                return matchesName || matchesKidsNick;
            })
            .sort((a, b) => {
                const totalPriceA = getUserWalletTokens(a.id).reduce((sum, t) => sum + t.totalPrice, 0);
                const totalPriceB = getUserWalletTokens(b.id).reduce((sum, t) => sum + t.totalPrice, 0);

                if (totalPriceA !== totalPriceB) {
                    return totalPriceB - totalPriceA; // sort by total price in descending order
                }

                return a.name.localeCompare(b.name); // sort by name in ascending order
            });
    }, [users, searchTerm]);


    const handleReset = () => {
        setSearchTerm('');
    };

    return (
        <Container>
            <UserBar title={'Гаманці'}/>
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <TextField id="input-with-sx" label="Пошук" variant="standard"
                                   value={searchTerm}
                                   onChange={(e) => setSearchTerm(e.target.value)}/>
                        {searchTerm && <IconButton onClick={handleReset} color="inherit" size="small">
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                    </Box>
                </Grid>
            </Grid>
            {error && <Typography color="error">{error}</Typography>}
            {isLoading && <Skeleton animation="wave" style={{margin: '2% 0', padding: '15px'}} variant="rounded" width={'100%'} height={200} />}
            {filteredUsers.map((user, index) => (
                <Card elevation={8} style={{margin:'5px', padding:'10px', borderRadius: '15px' }} key={index}>
                    <CardHeader style={{padding: '8px 16px'}}
                                avatar={
                            <Avatar aria-label="recipe">
                                {user.name.split(' ').map(n => n[0]).join('')}
                            </Avatar>
                        }
                                title={user.name}
                    />
                    <CardContent>
                        <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '1px 0'}}>
                            {getUserWalletTokens(user.id).map((token, idx) => (
                                token.amount > 0 &&
                                    <Grid item key={idx}>
                                        <Chip label={`${token.name}: ${parseFloat(token.amount.toFixed(7))}`}
                                              variant="outlined"/>
                                    </Grid>
                            ))}
                        </Grid>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{margin: '10px 5px'}}>
                            Загальна цінність активів: {getUserWalletTokens(user.id).reduce((sum, t) => sum + t.totalPrice, 0).toFixed(2)} QCC
                        </Typography>
                        <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '1px 0'}}>
                            {getUserKids(user.id).map((kid, idx) => (
                                <Grid item key={idx}>
                                    <Chip label={`${kid.nick || '?'} - ${kid.role || '?'}`}
                                          variant="contained"/>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            ))}
            <Footer />
        </Container>
    );
};

export default UserTokenList;
