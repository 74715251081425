import React, { useEffect, useState } from 'react';
import dynamoDb from '../db/awsConfig';

const TestConnection = () => {
    const [status, setStatus] = useState('Connecting...');

    const settingsData = [
        { name: "startPrice", description: "Стартова ціна токена", value: "25" },
        { name: "priceIncrease", description: "Поріг збільшення ціни токену", value: "1" },
        { name: "minAmount", description: "Мінімальна кількість токенів для чистки", value: "0.0000001" },
    ];

    const createSettingsIfNotExists = async (settings) => {
        for (const setting of settings) {
            try {
                // Проверка существования элемента
                const paramsGet = {
                    TableName: 'Settings', // Замените на имя вашей таблицы
                    Key: { name: setting.name },
                };

                const data = await dynamoDb.get(paramsGet).promise();
                if (data.Item) {
                    console.log(`Setting ${setting.name} уже существует.`);
                    continue;
                }

                // Создание нового элемента
                const paramsPut = {
                    TableName: 'Settings',
                    Item: setting,
                };

                await dynamoDb.put(paramsPut).promise();
                console.log(`Setting ${setting.name} успешно создан.`);
            } catch (error) {
                console.error(`Ошибка обработки ${setting.name}:`, error);
            }
        }
    };

    useEffect(() => {
        const testConnection = async () => {
            try {
                // Простое тестовое подключение (как в вашем изначальном коде)
                const params = { TableName: 'Settings', Limit: 1 };
                await dynamoDb.scan(params).promise();
                console.log(params)
                setStatus(`Connection successful: ${params}`);

                // Вызов функции создания элементов
                await createSettingsIfNotExists(settingsData);
            } catch (error) {
                console.error('Ошибка подключения:', error);
                setStatus('Connection failed');
            }
        };

        testConnection();
    }, []);

    return <p>{status}</p>;
};

export default TestConnection;
