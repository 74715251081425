import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, IconButton, List, ListItem, ListItemText, TextField } from '@mui/material';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Skeleton from "@mui/material/Skeleton";
import { AccountCircle } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { nanoid } from 'nanoid';
import {fetchUsers, addUser, deleteUser, getSettings} from '../db/dbService'; // Import the database service functions

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [settings, setSettings] = useState([]);
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const result = await fetchUsers();
                setUsers(result);

                const settingsData = await getSettings();
                setSettings(settingsData);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [isChanged]);

    const handleAddUser = async () => {
        if (!name) {
            setError('User name cannot be empty.');
            return;
        }

        setIsChanged(true);
        try {
            const newUserId = nanoid();
            const newUser = {
                id: newUserId,
                name: name,
                wallet: [{userId:newUserId, amount: Number(settings.find(s => s.name === 'startTokenAmount').value) || 0}],
                token: {title: name+'Coin', price: Number(settings.find(s => s.name === 'startPrice').value) || 0}
            };

            await addUser(newUser);
            setUsers((prevUsers) => [...prevUsers, newUser]);
            setName('');
            setError('');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsChanged(false);
        }
    };

    const handleDeleteUser = async (userId) => {
        const confirmDelete = window.confirm("Ви точно хочете видалити користувача?");
        if (!confirmDelete) {
            return;
        }

        setIsChanged(true);
        try {
            await deleteUser(userId);
            setUsers((prevUsers) => prevUsers.filter(user => user.id !== userId));
            setError('');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsChanged(false);
        }
    };

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleReset = () => {
        setSearchTerm('');
    };

    return (
        <Container>
            {isLoading && users.length === 0 && <Skeleton animation="wave" style={{ margin: '2% 0', padding: '15px' }} variant="rounded" width={'100%'} height={80} />}
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{ margin: '20px 0' }}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <TextField
                            id="input-with-sx"
                            label="Пошук"
                            variant="standard"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm && <IconButton onClick={handleReset} color="inherit" size="small">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
            </Grid>

            <List>
                {filteredUsers.map(user => (
                    <ListItem key={user.id}>
                        <ListItemText primary={user.name} />
                        <LoadingButton
                            style={{ margin: '5px' }}
                            loading={isChanged}
                            loadingPosition="start"
                            size="large"
                            startIcon={<DeleteForeverIcon fontSize="inherit" />}
                            variant="contained"
                            onClick={() => handleDeleteUser(user.id)}
                        >
                            Видалити
                        </LoadingButton>
                    </ListItem>
                ))}
            </List>

            <TextField
                label="User Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={!!isChanged}
            />

            <LoadingButton
                style={{ margin: '5px' }}
                loading={isChanged}
                loadingPosition="start"
                size="large"
                startIcon={<AddCircleIcon fontSize="inherit" />}
                variant="contained"
                onClick={handleAddUser}
            >
                Додати
            </LoadingButton>

            {error && <p>{error}</p>}
        </Container>
    );
};

export default UserList;
