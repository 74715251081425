import React, { useState } from 'react';
import { Container, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserBar from "../components/UserBar";
import Footer from "../components/Footer";
import dynamoDb from '../db/awsConfig';
import LoadingButton from "@mui/lab/LoadingButton";

const PinCodeInput = ({ onAuthenticate }) => {
    const [pin, setPin] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handlePinChange = (event) => {
        setPin(event.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            // Запрос к DynamoDB для получения текущего пина
            const params = {
                TableName: 'Settings',  // Замените на название вашей таблицы
                Key: {
                    name: 'pincode'  // Предполагаем, что в таблице есть запись с ключом name = 'pincode'
                }
            };

            const result = await dynamoDb.get(params).promise();
            if (result.Item && result.Item.value === pin) {
                onAuthenticate(true); // Успешная аутентификация
                navigate('/admin');   // Переход на страницу администрирования
            } else {
                setError(true); // Неверный пин
            }
        } catch (err) {
            console.error("Error verifying PIN:", err);
            setError(true); // Ошибка при запросе к базе данных
        } finally {
            setIsLoading(false); // Завершаем загрузку
        }
    };

    return (
        <Container>
            <UserBar title={'Вхід'} />
            <TextField
                type="password"
                value={pin}
                onChange={handlePinChange}
                error={error}
                helperText={error ? 'Невірний PIN' : ''}
                label="PIN Code"
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={!!isLoading}
            />
            <LoadingButton
                style={{ margin: '5px' }}
                loading={isLoading}
                size="large"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Перевірити
            </LoadingButton>
            <Footer />
        </Container>
    );
};

export default PinCodeInput;
