import React, {useState, useEffect} from 'react';
import {
    Typography,
    Container,
    Grid,
    Box,
    Avatar,
    Chip,
    Paper, lighten, Badge
} from '@mui/material';
import UserBar from "../components/UserBar";
import Footer from "../components/Footer";
import Skeleton from "@mui/material/Skeleton";
import {useTheme} from '@mui/material/styles';
import {fetchUsers, getSettings} from "../db/dbService";

const TokenRanking = () => {
    const [users, setUsers] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const theme = useTheme();

    useEffect(() => {
        const fetchTokens = async () => {
            setIsLoading(true);
            try {
                const userData = await fetchUsers();
                setUsers(userData);
                setTokens(userData.filter(u => u.token.title !== 'QCCoin')
                    .map(u => ({
                        title: u.token.title,
                        price: u.token.price,
                        userId: u.id
                    })).sort((a, b) => a.price - b.price));
            } catch (err) {
                console.error("Error fetching tokens:", err);
                setError("Failed to load token data");
            } finally {
                setIsLoading(false);
            }
        };

        fetchTokens();
    }, []);

    const calculateTokenInWallets = (tokenUserId) => {
        let total = 0;
        users.forEach(user => {
            const tokenInWallet = user.wallet.find(t => t.userId.toString() === tokenUserId.toString());
            if (tokenInWallet) {
                total += Number(tokenInWallet.amount);
            }
        });
        return Math.round(total).toString();
    };

    const minPrice = tokens.length ? Math.min(...tokens.map(token => token.price)) : 0;
    const getTokenTitle = (userId) => {
        const user = users.find(u => u.id.toString() === userId.toString());
        return user ? user.token.title : 'Unknown User'
    }

    return (
        <Container>
            <UserBar title={'Ціни'}/>
            {error && <Typography color="error">{error}</Typography>}
            {isLoading &&
                <Skeleton animation="wave" style={{margin: '2% 0', padding: '15px'}} variant="rounded" width={'100%'}
                          height={80}/>}
            <Grid container spacing={1} justifyContent="center" style={{margin: '1% 0', padding: '5px'}}>
                {tokens.map((token, index) => {
                    const fillPercentage = minPrice ? (minPrice / token.price) * 100 : 0;
                    const tokenInWallets = calculateTokenInWallets(token.userId);
                    return (
                        <Grid item xs={12} sm={12} md={12} key={index}>
                            <Paper
                                elevation={6}
                                style={{
                                    margin: '5px',
                                    padding: '10px',
                                    borderRadius: '15px',
                                    background: `linear-gradient(to right, ${theme.palette.success.dark} ${fillPercentage}%, ${lighten(theme.palette.background.paper, 0.2)} ${fillPercentage}%)`
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Avatar sx={{
                                        width: 30,
                                        height: 30,
                                        bgcolor: theme.palette.success.light,
                                        color: theme.palette.text,
                                        border: '2px solid #9f9f9f'
                                    }}>{index + 1}</Avatar>
                                    <Box ml={2}>
                                        <Typography variant="h6" color="primary">
                                            <Badge badgeContent={tokenInWallets} color="warning" max={999}>
                                                {token.title}
                                            </Badge>
                                        </Typography>
                                    </Box>
                                    <Box ml={2}>
                                        <Chip
                                            label={`${token.price !== 0
                                                ? (token.price > 0
                                                    ? parseFloat((1 / token.price).toFixed(5))
                                                    : parseFloat((-token.price).toFixed(5)))
                                                : 0
                                                } QCC`}
                                            color="primary"/>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    );
                })}
            </Grid>
            <Footer/>
        </Container>
    );
};

export default TokenRanking;
