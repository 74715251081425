import React, { useState, useEffect } from 'react';
import { Typography, TextField, List, ListItem, ListItemText } from '@mui/material';
import {getSettings, updateSettings} from '../db/dbService';
import Skeleton from "@mui/material/Skeleton";
import LoadingButton from "@mui/lab/LoadingButton";

const Settings = () => {
    const [settings, setSettings] = useState([]);
    const [editedSettings, setEditedSettings] = useState({});
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        const fetchSettings = async () => {
            setIsLoading(true);
            try {
                const result = await getSettings();

                if (result) {
                    setSettings(result);
                    // Для редактирования сохраняем копию настроек
                    const edited = {};
                    result.forEach(setting => {
                        edited[setting.name] = setting.value;
                    });
                    setEditedSettings(edited);
                }
            } catch (err) {
                console.error("Error fetching settings:", err);
                setError("Failed to load settings");
            } finally {
                setIsLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleInputChange = (name, newValue) => {
        setEditedSettings(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };

    const handleSaveSettings = async () => {
        setIsChanged(true);
        try {
            const updatedSettings = settings.map(setting => ({
                ...setting,
                value: editedSettings[setting.name] // Обновляем значение для каждой настройки
            }));

            // Теперь обновим записи в DynamoDB
            for (const setting of updatedSettings) {
                await updateSettings(setting);
            }
            setError('');
        } catch (err) {
            console.error("Error saving settings:", err);
            setError("Failed to save settings");
        } finally {
            setIsChanged(false);
        }
    };

    return (
        <div>
            {error && <Typography color="error">{error}</Typography>}
            {isLoading && settings.length === 0 && <Skeleton animation="wave" style={{ margin: '2% 0', padding: '15px' }} variant="rounded" width={'100%'} height={80} />}
            <List>
                {settings.map(setting => (
                    <ListItem key={setting.name}>
                        <ListItemText primary={setting.description} />
                        <TextField
                            value={editedSettings[setting.name] || ''}
                            onChange={(e) => handleInputChange(setting.name, e.target.value)}
                            style={{ marginLeft: '1rem', width: '10rem' }}
                            disabled={isChanged}
                        />
                    </ListItem>
                ))}
            </List>
            <LoadingButton
                style={{ margin: '5px', marginTop: '1rem' }}
                loading={isChanged}
                size="large"
                variant="contained"
                onClick={handleSaveSettings}
            >
                Зберегти
            </LoadingButton>
        </div>
    );
};

export default Settings;
